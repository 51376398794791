<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="title"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CRow>
					<CCol>
						<component
							:is="formComponentName"
							ref="form-sku"
							:sku-list="localSkuList"
							:is-validate-product-compare="isValidateProductCompare"
							@onGetProduct="handleGet"
							@onRemove="handleRemove"
						>
							<template #no-item>
								<div
									class="no-item d-flex text-center"
									data-test-id="no-item"
								>
									<div class="d-block">
										<div class="no-sku font-weight-bolder color-black-45">
											No SKU yet
										</div>
										<div class="typo-body-2 color-black-45">
											Add "SKU number" in order to specific SKU
										</div>
									</div>
								</div>
							</template>
						</component>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					:total-select="localSkuList.length"
					@onClear="handleClear"
					@onCancel="handleCancel"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
		<BaseModalConfirmDelete
			ref="modal-confirm"
			title="Leave without save?"
			description="All recently added SKUs will be discarded from SKU list"
			class="modal-on-modal"
			delete-button-text="Leave without save"
			@onSuccess="handleReset"
		/>
	</div>
</template>


<script>
import cloneDeep from 'lodash/cloneDeep';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import ModalHeaderWrapper from './ModalHeaderWrapper.vue';
import FormGetProductBySKU from './FormGetProductBySKU.vue';
import FormGetProductBySKUMultiple from './FormGetProductBySKUMultiple.vue';

export default {
	name: 'ModalSKU',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		FormGetProductBySKU,
		FormGetProductBySKUMultiple,
	},
	props: {
		title: {
			type: String,
			default: 'Select SKU',
		},
		skuList: {
			type: Array,
			default: () => [],
		},
		isMultiple: {
			type: Boolean,
			default: false,
		},
		isValidateProductCompare: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isShow: false,
			localSkuList: [],
		};
	},
	computed: {
		formComponentName() {
			return this.isMultiple
				? 'FormGetProductBySKUMultiple'
				: 'FormGetProductBySKU';
		},
	},
	created() {
		this.localSkuList = cloneDeep(this.skuList);
	},
	methods: {
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
			this.$refs['form-sku'].resetState();
		},
		handleGet(products) {
			this.localSkuList = [...products];
		},
		handleRemove(product) {
			this.localSkuList = this.localSkuList.filter((skuProduct) => skuProduct.sku !== product.sku);
		},
		handleCancel() {
			this.$refs['modal-confirm'].open();
		},
		handleReset() {
			this.localSkuList = cloneDeep(this.skuList);
			this.close();
		},
		handleClear() {
			this.localSkuList = [];
		},
		handleSubmit() {
			this.isShow = false;
			this.$emit('onConfirm', this.localSkuList);
		},
	},
};
</script>

<style lang="scss" scoped>
	.no-item {
		min-height: rem(200);
		justify-content: center;
		align-items: center;
	}

	::v-deep .list-item {
		min-height: rem(200);
		padding: 0;
		margin: 0;
	}
</style>
